import React, {forwardRef} from 'react'
import clsx from 'clsx'
import Link from '../../../Link'
import {
	btnLink,
	btnLinkWhite,
	btnLinkWhitePrimary,
	btnLinkSecondary,
} from './BtnLink.module.scss'

const BtnLink = forwardRef((props, ref) => {
	const {variant, href, cta, useButton } = props
	const btnClass = clsx(
		`btn btn-link`,
		btnLink,
		variant === 'white' ? btnLinkWhite : '',
		variant === 'white-primary' ? btnLinkWhitePrimary : '',
		variant === 'secondary' ? btnLinkSecondary : ''
	)

	return (
		<>
			{useButton ? (
				<button className={btnClass} {...props} ref={ref}>
					{cta}
				</button>
			) : (
				<Link to={href || ''} className={btnClass} ref={ref}>
						{cta}
				</Link>
			)}
		</>
	)
});

export default BtnLink
